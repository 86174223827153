// Load the navbar dynamically
fetch('navbar.html')
  .then(response => response.text())
  .then(data => {
    document.getElementById('navbar-placeholder').innerHTML=data;
    const logoutButton=document.getElementById('logout-button');
    logoutButton.addEventListener('click', async () => {
      return await logout();
    });
  })
  .catch(error => alert('Error loading navbar: '+error));

async function logout() {
  try {
    const response=await fetch('/api/logout', { method: 'POST' });
    if(response.ok) {
      window.location.href='/session.html';
    } else {
      console.error('Logout failed');
    }
  } catch(err) {
    console.error('Logout error:', err);
  }
}